<template>
  <el-container>
    <el-header class="header">
      <!-- <div class="logo">
        <el-image src="http://fuxi.umeng100.com/img/logo.fb812ca6.png" style="width: 180px;" fit="contain" />
      </div> -->
      <el-row class="header-row">
        <el-col :span="11">
        </el-col>
        <el-col :span="3">
          <h2>畅学订单系统</h2>
        </el-col>
        <el-col :span="9">
        </el-col>
        <el-col :span="1">
          <div class="user-info">
            <el-dropdown>
              <template #default>
                <span class="el-dropdown-link">
                  {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </template>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-container class="main-container">
      <el-aside width="200px" class="home-aside">
        <el-menu @select="handleMenuSelect" text-color="#000" class="home-menu">
          <el-sub-menu index="1">
            <template #title>数据中心</template>
            <!-- <el-menu-item index="create-channel" v-if="isAdmin">渠道管理</el-menu-item> -->
            <!-- <el-menu-item index="create-course" v-if="isAdmin">课程管理</el-menu-item> -->
            <!-- <el-menu-item index="exp-course" v-if="isAdmin">体验课订单</el-menu-item> -->
            <el-menu-item index="order-data">销量看板</el-menu-item>
            <!-- <el-menu-item index="transform-data">转化看板</el-menu-item> -->
          </el-sub-menu>
          <el-sub-menu index="2">
            <template #title>设置</template>
            <el-menu-item index="user-manage" v-if="isAdmin">用户管理</el-menu-item>
            <el-menu-item index="change-password">修改密码</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-main class="main-wrapper">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
  
<script>
export default {
  name: 'Home',
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    username() {
      return this.$store.getters.getUser.username;
    }
  },
  methods: {
    handleMenuSelect(index) {
      this.$router.push(`/home/${index}`);
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    }
  },
  created() {
    this.$store.dispatch('fetchUser').catch(() => {
      this.$router.push('/login');
    });
  }
};
</script>
  
<style>
.home-aside {
  background-color: white;
}

.el-menu {
  border: 0 !important;
}

.el-header {
  position: fixed;
  top: 0;
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  background-color: #ff6a00;
  color: white;
  /* padding-right: 20px; */
  box-sizing: border-box;
  z-index: 1000;
  font-family: cursive;
}

.header-row {
  width: 100%;
  height: 100%;
}

.header-row .el-col {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-wrapper {
  background-color: white;
  margin: 16px;
}

.main-container {
  margin-top: 60px;
  background-color: #f0f2f5;
}

.logo img {
  padding: 0;
  height: 60px;
}

.user-info {
  /* display: flex; */
  /* align-items: center; */

}

.user-info span {
  cursor: pointer;
  color: white;
  font-family: emoji;
}
</style>
  