<template>
    <div id="app">
        <router-view />
    </div>
</template>
  
<script>
export default {
    name: 'App',
};
</script>
  
<style>
/* 全局样式 */
body,
html,
#app {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.el-container {
    height: 100%;
}

.el-header {
    box-sizing: border-box;
}
</style>
  