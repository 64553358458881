import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    token: localStorage.getItem('token') || '',
    user: {}
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    login({ commit }, user) {
      return axios.post('/login', user)
        .then(response => {
          const token = response.data.token;
          localStorage.setItem('token', token);
          commit('setToken', token);
          return response;
        });
    },
    fetchUser({ commit }) {
      return axios.get('/api/v1/profile', {
        headers: { Authorization: `Bearer ${this.state.token}` }
      }).then(response => {
        commit('setUser', response.data.user);
        return response;
      });
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      commit('setToken', '');
      commit('setUser', {});
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getUser: state => state.user,
    isAdmin: state => state.user.role === 'admin'
  }
});

export default store;
