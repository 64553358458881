<template>
  <div class="login-container">
    <el-card class="login-card" style="width: 20%;background-color: rgba(240, 242, 245, 0.2); border: none;">
      <h2 style="color: white; text-align: center;">欢迎登录</h2>
      <el-form autocomplete="on" label-width="auto">
        <el-form-item>
          <el-input v-model="username" type="text" required name="username" autocomplete="username"
            placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="password" type="password" required name="password" autocomplete="current-password"
            placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" style="width: 100%;">立即登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- <div class="beian">
      <p>
        <a class="text-color" href="https://beian.miit.gov.cn" target="_blank">冀ICP备18015449号-1</a>
      </p>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }).then(() => {
        this.$router.push('/home');
      }).catch(error => {
        this.$message.error('Login failed: ' + error.response.data.error);
      });
    }
  }
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/bg.jpg');
  background-size: cover;
}

.login-card {
  width: 30%;
  padding: 20px;
  background-color: rgba(240, 242, 245, 0.2);
  border: none;
}

.el-form-item__label {
  color: white !important;
}

.beian {
  position: fixed;
  text-align: center;
  bottom: 10px;
}

.text-color {
  color: #bbb;
  font-size: 12px;
  text-decoration: none;
}

.text-color:hover {
  color: white;
}
</style>
