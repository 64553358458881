<template>
  <div>
    <h2>Create Channel</h2>
    <form @submit.prevent="createChannel">
      <div>
        <label>Name</label>
        <input v-model="name" type="text" required />
      </div>
      <div>
        <label>Description</label>
        <input v-model="description" type="text" />
      </div>
      <button type="submit">Create</button>
    </form>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      description: ''
    };
  },
  methods: {
    createChannel() {
      axios.post('/api/channels', {
        name: this.name,
        description: this.description
      }).then(() => {
        alert('Channel created successfully');
        this.name = '';
        this.description = '';
      }).catch(error => {
        alert('Error creating channel: ' + error.response.data.error);
      });
    }
  }
};
</script>
  
<style>
/* 添加样式 */
</style>
  